<template>
    <div class="trade-record">
        <HeaderComp class="activity-header" title="充值记录" @return="doBack" />
        <div v-if="afterLoad && recordList.length === 0" class="content-wrap">
            <div class="empty"></div>
            <div class="msg">你还没有充值记录&gt;ω&lt;</div>
        </div>
        <div v-else class="content-wrap">
            <div class="record-item" v-for="(item, index) in recordList" :key="index">
                <div class="left">
                    <div class="name">{{ typeMap[item.type] }}</div>
                    <div class="date">{{ item.time | formatDate('yyyy-MM-dd hh:mm') }}</div>
                </div>
                <div class="desc">{{ item.desc }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from '@/modules/request.js';
import HeaderComp from '@/components/header/index.vue';

export default {
    components: {
        HeaderComp,
    },
    watch: {
        afterLoad(newVal) {
            if (newVal && this.since >= 0) {
                this.bindScroll();
            }
        },
    },
    data: function () {
        return {
            recordList: [],
            from: '',
            since: 0,
            len: 20,
            onRequest: false,
            afterLoad: false,
            typeMap: {
                sign: '签到',
                recharge: '充值',
                vip: '充值',
            },
        };
    },
    methods: {
        doBack() {
            if (this.from) {
                this.$router.back();
                return;
            }
            this.$router.replace({
                name: 'Index',
            });
        },
        async getData() {
            this.onRequest = true;
            const { data } = await get({
                url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/sales/recharge/history',
                data: {
                    since: this.since,
                    limit: this.len,
                },
            });
            const { records, since } = data;
            this.onRequest = false;
            this.since = since;
            if (this.since < 0) {
                this.removeScroll();
            }
            this.recordList = this.recordList.concat(
                records.filter((r) => Object.prototype.hasOwnProperty.call(this.typeMap, r.type))
            );
        },
        removeScroll() {
            const scrollDom = document.querySelector('.trade-record .content-wrap');
            scrollDom.removeEventListener('scroll', this.scrollHandler);
        },
        scrollHandler() {
            const scrollDom = document.querySelector('.trade-record .content-wrap');
            const { clientHeight, scrollTop, scrollHeight } = scrollDom;
            const dis = scrollHeight - clientHeight - scrollTop;
            if (this.since >= 0 && !this.onRequest && dis < 400) {
                this.getData();
            }
        },
        bindScroll() {
            const scrollDom = document.querySelector('.trade-record .content-wrap');
            scrollDom.addEventListener('scroll', this.scrollHandler);
        },
    },
    async created() {
        loading.show();
        document.title = '充值记录';
        await this.getData();
        loading.hide();
        this.afterLoad = true;
    },
    mounted() {
        this.sa('CommonPageOpen', {
            uid: this.$store.state.userInfo.uid,
            CurPage: '充值记录',
            PrePage: this.from,
        });
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from.meta.trackName;
        });
    },
};
</script>
<style scoped>
.trade-record {
    position: relative;
    height: 100vh;
}
.header {
    z-index: 10;
    width: 100%;
    height: 48px;
    font-size: 15px;
    text-align: center;
    color: #333;
    background-color: #fff;
    line-height: 48px;
}
.back-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    width: 24px;
    height: 24px;
    background: url('./icon_back.png') no-repeat center/24px;
}
.content-wrap {
    overflow-y: scroll;
    padding: 0 16px;
    height: calc(100% - 48px);
}
.content-wrap .empty {
    margin: 79px auto 8px;
    width: 125px;
    height: 125px;
    background: url('./empty.png') no-repeat center/cover;
}
.content-wrap .msg {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #ffd3d3;
    line-height: 17px;
}
.content-wrap .record-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    height: 69px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    flex-direction: row;
}
.record-item:last-child {
    border-bottom: none;
}
.record-item .left {
    display: flex;
    flex-direction: column;
}
.left .name {
    margin-bottom: 6px;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    line-height: 18px;
}
.left .date {
    font-size: 12px;
    font-weight: 400;
    color: #999;
    line-height: 15px;
}
.record-item .desc {
    font-size: 15px;
    font-weight: 400;
    color: #333;
}
</style>
