import { render, staticRenderFns } from "./TradeRecord.vue?vue&type=template&id=46f7697c&scoped=true&"
import script from "./TradeRecord.vue?vue&type=script&lang=js&"
export * from "./TradeRecord.vue?vue&type=script&lang=js&"
import style0 from "./TradeRecord.vue?vue&type=style&index=0&id=46f7697c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46f7697c",
  null
  
)

export default component.exports